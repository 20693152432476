import styled from 'styled-components';
import { BlockLayout, Button } from '@/ui';
import { COLORS, DEVICES, pickThemeFontStyles, SHADOWS } from '@/theme';
import { ArrowRightIcon } from '@/icons';

export const StyledRatioWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  padding-top: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
`;

export const StyledRatioBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  ${pickThemeFontStyles('12', '14', 'BOLD')};
  color: ${COLORS.GRAY};

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: column;
  }
`;

export const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;
`;

export const StyledButton = styled(Button)`
  &&& {
    max-width: 12rem;
    font-size: 0.75rem;
    height: 2rem;
    padding: 0.75rem;
    background: ${COLORS.WHITE};
    color: ${COLORS.LIQUORICE_BLACK};
    box-shadow: ${SHADOWS.MEDIUM};
    border: none;
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
  }
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-left: 0.5rem;
  path {
    fill: ${COLORS.LIQUORICE_BLACK};
  }
`;

export const MetricsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  :first-child {
    align-items: flex-start;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    align-items: center;
  }
`;

export const MetricsCardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;
  align-items: flex-start;

  :first-child {
    align-items: flex-start;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    align-items: center;
  }
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 0.3rem;
  @media screen and ${DEVICES.LAPTOP_S} {
    justify-content: flex-start;
  }
  svg {
    transform: scale(0.9);
  }
`;

export const StyledChannelLink = styled.a`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  margin-left: 0.3rem;
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  min-height: 6rem;
  margin: 2rem 0rem;
  flex-direction: column;
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 12rem;
  }
`;

export const StyledOverviewWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledLabel = styled.div<{ $isBold?: boolean }>`
  ${({ $isBold }) =>
    $isBold
      ? pickThemeFontStyles('20', '26', 'BOLD')
      : pickThemeFontStyles('14', '20', 'SEMI_BOLD')};
  color: ${COLORS.LIQUORICE_BLACK};
`;

export const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  max-width: 12rem;
  gap: 0.5rem;
  ${pickThemeFontStyles('12', '14', 'BOLD')};
  color: ${COLORS.GRAY};
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('20', '25', 'NORMAL')};
  color: ${COLORS.BLACK};
`;

export const StyledSubInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  ${pickThemeFontStyles('12', '14', 'BOLD')};
  color: ${COLORS.GRAY};
`;

export const StyledOverviewDetails = styled(BlockLayout)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &&& {
    padding: 0.5rem 1rem;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      padding: 1.25rem 1.875rem;
    }
  }
`;

export const StyledBlock = styled.div`
  width: 100%;
`;

export const StyledBlockWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.25rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const HorizontalDivider = styled.div`
  height: 1px;
  background: ${COLORS.SOLITUDE_GRAY};
  width: 100%;
  margin: 0.625rem 0;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  background: ${COLORS.SOLITUDE_GRAY};
  margin: 0 2rem;
`;

export const StyledText = styled.div<{ $isColored?: boolean }>`
  ${pickThemeFontStyles('14', '20', 'BOLD')};
  color: ${({ $isColored }) =>
    $isColored ? COLORS.GOSSAMER : COLORS.CORN_FLOWER_BLUE};
`;

export const StyledBadge = styled.div`
  display: flex;
  max-width: 8rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  ${pickThemeFontStyles('10', '16', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  background: ${COLORS.LIGHT_BLUE};
`;
