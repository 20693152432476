// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledOverviewWrap,
  StyledRow,
  StyledTitle,
  MetricsListWrapper,
  StyledInfoTitles,
  StyledTitles,
} from './CampaignResultsStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import PerformanceMetricsCard from './PerformanceMetricsCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IParticipationOverview } from '@/models/campaigns.model';

// Hooks
import { useInfiniteScroll } from '@/hooks';

type TPerformanceMetrics = {
  participants: {
    items: IParticipationOverview[] | null;
    hasMore: boolean;
  };
  getNextParticipantsOverview: () => void;
  isLoading: boolean;
};

const PerformanceMetrics = ({
  isLoading,
  participants,
  getNextParticipantsOverview,
}: TPerformanceMetrics) => {
  const { t } = useTranslation(['campaigns']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedProjectsList = useMemo(() => {
    return participants?.items?.map((item) => {
      return <PerformanceMetricsCard data={item} key={item?.id} />;
    });
  }, [participants]);

  useInfiniteScroll({
    callback: getNextParticipantsOverview,
    ref: loaderRef,
    isLoading,
    hasMore: participants?.hasMore,
  });

  return (
    <StyledOverviewWrap>
      <StyledRow>
        <StyledTitle>{t('campaign_metrics_title')}</StyledTitle>
        {/* <StyledButton> // Hidden for now
          {t('campaign_metrics_export')}
          <StyledArrowRightIcon />
        </StyledButton> */}
      </StyledRow>
      <MetricsListWrapper>
        <DesktopOnly>
          <StyledInfoTitles>
            <StyledTitles>{t('campaign_metrics_kol_alias')}</StyledTitles>
            <StyledTitles>{t('campaign_metrics_profile')}</StyledTitles>
            <StyledTitles>{t('campaign_metrics_followers')}</StyledTitles>
            <StyledTitles>{t('campaign_metrics_engagement')}</StyledTitles>
            <StyledTitles>{t('campaign_metrics_reach')}</StyledTitles>
            <StyledTitles>{t('campaign_metrics_planned_posts')}</StyledTitles>
            <StyledTitles>{t('campaign_metrics_delivered_posts')}</StyledTitles>
            <StyledTitles>
              {t('campaign_metrics_total_participation')}
            </StyledTitles>
          </StyledInfoTitles>
        </DesktopOnly>
        {mappedProjectsList}
        {isLoading && (
          <StyledSpinnerWrap>
            <Spinner />
          </StyledSpinnerWrap>
        )}
        <div ref={loaderRef} />
      </MetricsListWrapper>
    </StyledOverviewWrap>
  );
};

export default PerformanceMetrics;
